import {useState} from "react";
import {AxiosError} from "axios";
import CoursesApiService from "../services/courses-api.service";

const UseCoursesApi = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<AxiosError | null>(null);

    const startPaymentForCourse = async (courseName: string): Promise<string | null> => {
        setLoading(true);
        setError(null);

        return await CoursesApiService.startPaymentForCourse(courseName)
            .then((url): string => url)
            .catch((err: AxiosError): null => {
                setError(err);
                return null;
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return {
        loading,
        error,
        startPaymentForCourse
    }
}

export default UseCoursesApi
