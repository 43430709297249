import ApiService from "../../core/services/api.service";

class CoursesApiService extends ApiService {

    constructor() {
        super();
    }

    public async startPaymentForCourse(areaName: string): Promise<string> {
        return await this.post<string>(`/tpay/start-payment-course/${areaName}`, {})
            .then(res => res || '')
    }
}

export default new CoursesApiService();
