import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {Course} from "../types/course.interface";
import {CheckCircleIcon} from "@heroicons/react/24/solid";
import SkeletonCourseDetailed from "./skeleton-course-detailed";
import {PhotoIcon} from "@heroicons/react/24/outline";
import {ArrowRightIcon} from "@heroicons/react/20/solid";
import useAreaApi from "../../dashboard/hooks/use-area-api";
import {CurrencyHelper} from "../../../utils/currency-helper";
import useCoursesApi from "../hooks/use-courses-api";
import PrimaryButton from "../../common/components/primary-button";
import useAuth from "../../auth/hooks/useAuth";
import {AuthenticatedUser} from "../../auth/types/authenticated-user.interface";

const CourseDetailed = () => {
    const [isStartedPayment, setIsStartedPayment] = useState(false);
    const [courseDetails, setCourseDetails] = useState<Course | null>(null);
    const navigate = useNavigate();
    const {area: nameCourse} = useParams();
    const {fetchCourseByName, loading} = useAreaApi();
    const {startPaymentForCourse} = useCoursesApi();
    const {currentUser, updateAuthenticationField} = useAuth();

    const isPurchasedCourse = () => !!currentUser?.userCourses.find(course => course.id === courseDetails?.id)

    const handlePurchase = async () => {
        if (!isPurchasedCourse()) {
            setIsStartedPayment(true)
            try {
                await startPaymentForCourse(courseDetails?.name!)
                    .then(paymentUrl => {
                        if (paymentUrl) {
                            const updatedUser: AuthenticatedUser = {
                                ...currentUser!,
                                userCourses: [...currentUser?.userCourses!, {
                                    name: courseDetails?.name!,
                                    id: courseDetails?.id!
                                }]
                            }
                            updateAuthenticationField('user', updatedUser);
                            window.location.href = paymentUrl;
                        }
                    })
                    .catch(() => {
                    })
                    .finally(() => setIsStartedPayment(false))
            } catch (error) {
                setIsStartedPayment(false)
                console.error("Error initiating payment:", error);
            }
        }
    };

    useEffect(() => {
        fetchCourseByName(nameCourse!)
            .then(course => course ? setCourseDetails(course) : navigate('/main/courses'))
            .catch();
    }, [nameCourse]);

    if (!courseDetails) return <SkeletonCourseDetailed/>;

    return (
        <div
            className="flex flex-col sm:flex-row sm:gap-x-10 max-w-lg sm:max-w-full sm:min-w-full mx-auto sm:mx-0 rounded-lg overflow-hidden w-full">
            <div className="sm:flex-grow w-full sm:max-w-[360px]">
                <div className="relative w-full rounded-lg sm:h-60 bg-gray-200 sm:w-full min-w-28">
                    {!!courseDetails.imageBase64 ? (
                        <img
                            className="w-full object-cover h-full"
                            alt={courseDetails.name}
                            src={`data:image/jpeg;base64,${courseDetails.imageBase64}`}
                        />
                    ) : (
                        <PhotoIcon className="w-full h-48 text-gray-500"/>
                    )}
                </div>

                <div className="p-4 flex justify-end items-center my-3">
                  <span className="text-2xl leading-8 font-normal">
                    cena: <span className="font-bold">{CurrencyHelper.formatToPLN(courseDetails.price)}</span>
                  </span>
                </div>

                <div className="px-4 pb-4">
                    <PrimaryButton
                        onClick={handlePurchase}
                        loading={isStartedPayment}
                        className="w-full bg-green-700 rounded-3xl text-white py-2 px-4 text-center
                    flex items-center justify-center shadow-md hover:bg-green-800 focus:bg-green-800 transition-all">
                        {isPurchasedCourse() ? 'Posiadane' : 'Przejdź do zakupu'}
                        {!isPurchasedCourse() && <ArrowRightIcon className="w-6 h-6 ml-3"/>}
                    </PrimaryButton>
                </div>
            </div>

            <div className="px-4 pb-6 mt-10 sm:mt-0">
                <h3 className="text-xl mb-4 leading-8 font-bold">Szczegóły kursu</h3>

                {courseDetails.subareas.map((subarea, index) => (
                    <div key={index} className="mb-5">
                        <h4 className="text-md mb-2 text-base leading-6 font-bold">
                            {subarea.name}
                        </h4>
                        <ul className="list-none space-y-2">
                            {subarea.chapters.map((chapter, idx) => (
                                <li key={idx} className="flex items-start">
                                    <CheckCircleIcon className="w-5 min-w-5 h-5 text-green-500 mr-2"/>
                                    {chapter}
                                </li>
                            ))}
                        </ul>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default CourseDetailed;
