import {useEffect, useState} from "react";
import useAreaApi from "../../dashboard/hooks/use-area-api";
import AreaSkeleton from "../../dashboard/components/area-skeleton";
import PreviewCourse from "./preview-course";
import EmptyListCourses from "./empty-list-courses";
import {Course} from "../types/course.interface";
import {MainArea} from "../../dashboard/types/main-area.interface";

const ListCourses = () => {
    const [courses, setCourses] = useState<MainArea[]>([])
    const [isEmpty, setIsEmpty] = useState(false);
    const {fetchCourses, loading, updateAreasStatus} = useAreaApi();

    useEffect(() => {
        updateAreasStatus()
            .then(() => {
                fetchCourses()
                    .then(res => {
                        const courses = res.filter(el => !el.hasAccess);
                        courses.length && setCourses(courses || [])
                        if (!courses.length) setIsEmpty(true)
                    })
            })
    }, [])

    const renderPreviewCourses = () => courses.map((course, id) => <PreviewCourse key={id} {...course} />)

    return (
        <>
            <header className='text-2xl leading-7 font-bold mb-8'>Sprawdź dostępne kursy</header>
            {!isEmpty
                ? (
                    <section
                        className='grid gap-4 sm:gap-6 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 mb-8'>
                        {!loading ? (
                            renderPreviewCourses()
                        ) : (
                            <>
                                {Array.from({length: 5}).map((i, index) => <AreaSkeleton key={index}/>)}
                            </>
                        )}
                    </section>
                )
                : <EmptyListCourses/>
            }
        </>
    )
}

export default ListCourses
