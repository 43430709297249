import React, {useState} from 'react';
import {Subscription} from "../types/subscription.interface";
import {CheckIcon} from "@heroicons/react/16/solid";
import {classNames} from "../../../utils/class-names";
import {CheckCircleIcon} from "@heroicons/react/24/solid";
import useSubscriptionApi from "../hooks/use-subscription-api";
import PrimaryButton from "../../common/components/primary-button";
import useAuth from "../../auth/hooks/useAuth";
import {AuthenticatedUser} from "../../auth/types/authenticated-user.interface";

interface SubscriptionCardProps {
    subscription: Subscription;
}

const SubscriptionCard: React.FC<SubscriptionCardProps> = ({subscription}) => {
    const [isStartedPayment, setIsStartedPayment] = useState(false);
    const {startPaymentForSubscription, getSubscriptionTrial} = useSubscriptionApi();
    const {currentUser, updateAuthenticationField} = useAuth();

    const isPurchasedSubscription = subscription.isActive

    const handlePurchase = async (isTrialAvailable: boolean) => {
        if (!isPurchasedSubscription) {
            setIsStartedPayment(true)
            try {
                if (isTrialAvailable) {
                    await getSubscriptionTrial(subscription.id)
                        .then((res) => {
                            if (res) {
                                subscription.isTrialAvailable = false
                                subscription.isActive = true
                                const updatedUserState: AuthenticatedUser = {
                                    ...currentUser!,
                                    isTrialSubscriptionAvailable: false,
                                }
                                updateAuthenticationField('user',updatedUserState)
                            }
                        })
                        .catch(() => {
                        })
                        .finally(() => setIsStartedPayment(false))
                } else {
                    await startPaymentForSubscription(subscription.id)
                        .then(paymentUrl => {
                            if (paymentUrl) {
                                window.location.href = paymentUrl;
                            }
                        })
                        .catch(() => {
                        })
                        .finally(() => setIsStartedPayment(false))
                }
            } catch (error) {
                setIsStartedPayment(false)
                console.error("Error initiating payment:", error);
            }
        }
    };

    return (
        <div className={classNames(
            'relative p-6 bg-white text-gray-900 min-h-[450px] rounded-xl flex flex-col' +
            '',
            subscription.recommended ? 'border border-green-600' : ' shadow-lg'
        )}>
            {subscription.recommended && (
                <div className='absolute top-0 left-0 px-3 translate-y-[-50%] translate-x-6 py-[6px] bg-green-600 uppercase text-white rounded-3xl text-xs
                leading-4 font-semibold tracking-wide'>
                    Najczęsciej wybierana
                </div>
            )}
            <div className='h-full overflow-hidden flex-grow flex-col flex'>
                <div className='h-fit'>
                    <h4 className="mb-4 text-xl leading-7 font-semibold">{subscription.name}</h4>
                    <p className="text-base leading-6 font-normal mb-12">{subscription.description}</p>
                    <div>
                        <span
                            className='text-4xl leading-10 font-extrabold tracking-tight mr-1'>{subscription.price} zł</span>
                        <span className="text-xl leading-7 font-semibold">/{subscription.subscriptionExpiryDate}</span>
                    </div>
                </div>
                <ul className="mt-4 list-none flex-grow">
                    {subscription.elements.map((element) => (
                        <li key={element.id} className="flex items-start mb-2">
                            <CheckIcon className='min-w-5 w-5 h-5 text-green-600 mr-3'/>
                            <span className='text-base leading-6 font-normal text-gray-600'>{element.name}</span>
                        </li>
                    ))}
                    {subscription.elements.map((element) => (
                        <li key={element.id} className="flex items-start mb-2">
                            <CheckIcon className='min-w-5 w-5 h-5 text-green-600 mr-3'/>
                            <span className='text-base leading-6 font-normal text-gray-600'>{element.name}</span>
                        </li>
                    ))}
                </ul>
            </div>
            <PrimaryButton
                disabled={isStartedPayment}
                onClick={handlePurchase.bind(this, subscription.isTrialAvailable)}
                className={classNames('w-full py-2 rounded-3xl min-h-fit shadow-md flex gap-3 items-center justify-center',
                    subscription.recommended
                        ? 'bg-green-700 text-white hover:bg-green-800 focus:bg-green-800'
                        : 'bg-green-100 text-green-900 hover:bg-green-200 focus:bg-green-200',
                    isPurchasedSubscription && subscription.recommended
                        ? '!bg-green-700 !font-bold cursor-auto'
                        : '',
                    isPurchasedSubscription && !subscription.recommended
                        ? '!bg-green-100 !font-bold cursor-auto'
                        : ''
                )}
            >
                {isPurchasedSubscription && <CheckCircleIcon className='w-5 h-5'/>}
                {isPurchasedSubscription ? 'Posiadane' : (
                    subscription.isTrialAvailable && !!currentUser?.isTrialSubscriptionAvailable ? 'Otrzymaj 7-dniowy trial' : 'Wykup dostęp'
                )}
            </PrimaryButton>
        </div>
    );
};

export default SubscriptionCard;
