import ApiService from "../../core/services/api.service";
import {GetMainAreasResponse} from "../types/get-main-areas.interface";
import {MainArea} from "../types/main-area.interface";
import {Subarea} from "../types/sub-area.interface";
import {Chapter} from "../types/chapter.interface";
import {GetChaptersResponse} from "../types/get-chapters.interface";
import {Course} from "../../courses/types/course.interface";
import {CoursesHistoryTransaction} from "../types/courses-history-transaction.interface";

class AreaApiService extends ApiService {

    constructor() {
        super();
    }

    public async getCoursesHistoryTransaction(): Promise<CoursesHistoryTransaction> {
        return await this.get<CoursesHistoryTransaction>('/areas/user-courses-history', {})
            .then(res => res || [])
    }

    public async getCourse(areaName: string): Promise<Course> {
        return await this.get<Course>('/areas/courses', {
            params: {areaName}
        })
            .then(res => res)
    }

    public async getMainAreas(): Promise<Array<MainArea>> {
        return await this.get<GetMainAreasResponse>('/areas/areas-for-user', {})
            .then(res => res || [])
    }

    public async updateStatusAreas(): Promise<void> {
        return await this.put<void>('/areas/update-user-completion-status', {}, {
            skipDefault404Handling: true
        })
    }

    public async getSubAreas(areaName: string): Promise<Array<Subarea>> {
        return await this.get<Array<Subarea>>(`/areas/${areaName}/subareas-for-user`, {})
            .then(res => res || [])
    }

    public async getChapters(subareaName: string): Promise<Array<Chapter>> {
        return await this.get<GetChaptersResponse>(`/chapters/${subareaName}/chapters-for-user`, {})
            .then(res => res || [])
    }
}

export default new AreaApiService();
